import React from "react"
import block from "bem-cn"
import "./style.scss"
import { sendYMEvent } from "../../helpers"

const b = block("download-app")

const getMobileOS = () => {
  if (typeof window === "undefined") {
    return ""
  }
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return "android"
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "ios"
  }
  return ""
}

export default function DownloadApp() {
  // const platform = getMobileOS()
  // const href = `https://liveapp.onelink.me/V9Nw/${platform}`
  const href = `https://liveapp.onelink.me/V9Nw/`
  return (
    <a
      onClick={() =>
        sendYMEvent({
          eventAction: "button_click",
          eventLabel: "skachat_prilozhenie",
        })
      }
      href={href}
      className={b()}
    >
      Скачать приложение
    </a>
  )
}
