import React from "react"
import { Helmet } from "react-helmet"
import { YM_COUNTER } from "../../constants"

export default function YM({ children }) {
  return (
    <div>
      <Helmet>
        <script type="text/javascript">
          {`window.dataLayer = window.dataLayer || []; window.dataLayer.push({ originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search })`}
        </script>
        {/* Yandex.Metrika counter */}
        <script type="text/javascript">
          {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(${YM_COUNTER}, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, trackHash:true, ecommerce:"dataLayer" })`}
        </script>
      </Helmet>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <div><img src="https://mc.yandex.ru/watch/${YM_COUNTER}" style="position:absolute; left:-9999px;" alt="" /></div>
          `,
        }}
      />
      {/* /Yandex.Metrika counter */}
      {children}
    </div>
  )
}
