import React from "react"
import { Helmet } from "react-helmet"
import block from "bem-cn"
import favicon from "../../images/favicon.ico"

import "./style.scss"
const b = block("header")

export default function Header() {
  return (
    <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
  )
}
