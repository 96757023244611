const getYMParams = ({
  event = "mtsLand",
  eventCategory = "landing_prilozhenie",
  eventAction,
  eventContent = null,
  buttonLocation = null,
  actionGroup = "interactions",
  eventLabel,
}) => ({
  // [event]: {
  abonent: null,
  currentTariff: null,
  screenName: null,
  touchPoint: "web",
  userAuth: 0,
  userId: 0,
  accountType: null,
  actionGroup,
  bannerId: null,
  bannerName: null,
  buttonLocation,
  deliveryType: null,
  eventProductPromoLabel: null,
  event,
  eventAction,
  eventCategory,
  eventContext: null,
  eventContent,
  eventLabel,
  eventProductAvailable: null,
  eventProductDeliveryTerms: null,
  eventPosition: null,
  eventValue: null,
  numberType: null,
  paymentType: null,
  productId: null,
  productName: null,
  regionId: null,
  region: null,
  ecommerce: null,
  // },
})

export default function sendYMEvent(event) {
  // if (process.env.GATSBY_ENV === "prod") {
  if (typeof window?.dataLayer !== "undefined") {
    window.dataLayer.push(getYMParams(event))
  } else if (typeof window !== "undefined") {
    console.warn(`DataLayer is not defined. Event =`, event)
  }
  // }
}
