import React from "react"
import block from "bem-cn"
import footerQr from "../../images/qr-footer.png"
import "./style.scss"

const b = block("qr-code-img")

export default function QrCodeImg({ isFooter = false }) {
  return <img className={b({ isFooter })} src={footerQr} alt="footer-qr" />
}
