import React from "react"

import Header from "../../components/Header"
import GTM from "../../components/GTM"
import YM from "../../components/YM"
import AppHeader from "../../components/AppHeader"
import AboutApp from "../../components/AboutApp"
import Questions from "../../components/Questions"
import Footer from "../../components/Footer"
import HowItWorks from "../HowItWorks/HowItWorks"

import "./style.scss"

export default function Main(props) {
  return (
    <React.Fragment>
      <YM>
        {/* <GTM> */}
        <Header />
        <div className="main">
          <AppHeader categories={props.categories} />
          {/* <HowItWorks /> */}
          <AboutApp />
          <Questions />
          <Footer />
        </div>
        {/* </GTM> */}
      </YM>
    </React.Fragment>
  )
}
