import React from "react"
import block from "bem-cn"
import logo from "../../images/logo.svg"
import logoMobile from "../../images/logo-mobile.svg"
import logoMtsLive from "../../images/logo-mts-live.svg"
import logoMtsLiveMobile from "../../images/logo-mts-live-mobile.svg"
import "./style.scss"
const b = block("logo")

export default function Logo() {
  return (
    <div className={b()}>
      <div className={b("container")}>
        <img className={b("logo")} src={logo} alt="logo" />
        <img
          className={b("logo-mts-live")}
          src={logoMtsLive}
          alt="logo-mts-live"
        />
        {/* <img className={b("logo-mobile")} src={logoMobile} alt="logo-mobile" /> */}
        {/* <img
          className={b("logo-mts-live-mobile")}
          src={logoMtsLiveMobile}
          alt="logo-mts-live-mobile"
        /> */}
        <span className={b("title")}>Приложение{"\n"}МТС LIVE</span>
      </div>
    </div>
  )
}
