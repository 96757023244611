import React from "react"
import block from "bem-cn"
import "./style.scss"

const b = block("qr-code-note")

export default function QrCodeNote({ isFooter = false }) {
  return (
    <div className={b({ isFooter })}>
      <div className={b("icon")}></div>
      <span className={b("text")}>
        Наведите камеру на QR-код,{"\n"}чтобы скачать приложение
      </span>
    </div>
  )
}
