import React from "react"
import block from "bem-cn"

import Content from "../../components/Content"
import DownloadApp from "../../components/DownloadApp"
import QrCodeNote from "../../components/QrCodeNote"
import QrCodeImg from "../../components/QrCodeImg"

import footerLogo from "../../images/footer-logo.png"
import footerQr from "../../images/qr-footer.png"
import vkIcon from "../../images/vk-icon.svg"
import tgIcon from "../../images/tg-icon.svg"
import okIcon from "../../images/ok-icon.svg"

import { sendYMEvent } from "../../helpers"
import "./style.scss"

const b = block("footer")

export default function Footer() {
  return (
    <div className={b()}>
      <div className={b("container")}>
        <Content>
          <div className={b("content")}>
            <div className={b("left-block")}>
              <span className={b("title")}>
                Планируйте{"\n"}свои{"\n"}развлечения{"\n"}с приложением{"\n"}
                МТС LIVE
              </span>
              <div className={b("dowload-app")}>
                <DownloadApp />
              </div>
              <div className={b("links-block")}>
                <div className={b("social-links")}>
                  <a
                    target="_blank"
                    onClick={() =>
                      sendYMEvent({
                        eventAction: "social_click",
                        eventLabel: "vk",
                      })
                    }
                    href={"https://vk.com/mts_live"}
                    className={b("social-link")}
                  >
                    <img className={b("social-icon")} src={vkIcon} alt="vk" />
                  </a>
                  <a
                    target="_blank"
                    onClick={() =>
                      sendYMEvent({
                        eventAction: "social_click",
                        eventLabel: "tg",
                      })
                    }
                    href={"https://t.me/mts_live"}
                    className={b("social-link")}
                  >
                    <img className={b("social-icon")} src={tgIcon} alt="tg" />
                  </a>
                  <a
                    target="_blank"
                    onClick={() =>
                      sendYMEvent({
                        eventAction: "social_click",
                        eventLabel: "ok",
                      })
                    }
                    href={"https://ok.ru/mts"}
                    className={b("social-link")}
                  >
                    <img className={b("social-icon")} src={okIcon} alt="ok" />
                  </a>
                </div>
                <a
                  target="_blank"
                  className={b("link-to-site")}
                  onClick={() =>
                    sendYMEvent({
                      eventAction: "element_click",
                      eventLabel: "prodolzhit_na_saite",
                    })
                  }
                  href={"https://live.mts.ru/"}
                >
                  Продолжить на сайте
                </a>
              </div>
            </div>
            <img
              className={b("footer-logo")}
              src={footerLogo}
              alt="footer-logo"
            />
            <div className={b("footer-qr-block")}>
              <QrCodeImg isFooter />
              <QrCodeNote isFooter />
            </div>
          </div>
        </Content>
      </div>
    </div>
  )
}
