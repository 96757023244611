import React from "react"
import block from "bem-cn"
import Marquee from "react-fast-marquee"

import starImg from "../../images/star.svg"
import iphone from "../../images/iphone.png"
import iphoneT from "../../images/iphone-t.png"
import headerQr from "../../images/qr-header.png"

import Logo from "../../components/Logo"
import DownloadApp from "../../components/DownloadApp"
import Content from "../../components/Content"
import QrCodeNote from "../../components/QrCodeNote"

import "./style.scss"

const b = block("app-header")

export default function AppHeader({ categories }) {
  return (
    <div className={b()}>
      <div className={b("tabs-container")}>
        <Marquee gradient={false} speed={30}>
          {categories.map((t, i) => {
            return (
              <div key={i} className={b("tabs-item")}>
                <span className={b("tab")}>{t.name}</span>
                <img className={b("tab-icon")} src={starImg} alt="star" />
              </div>
            )
          })}
        </Marquee>
      </div>
      <Content>
        <div className={b("container")}>
          <Logo />
          <div className={b("text-block")}>
            <h2 className={b("text-block-title")}>
              Городские развлечения{"\n"}у вас в кармане
            </h2>
            <span className={b("qr-mobile-text")}>
              Мобильное приложение для покупки{"\n"}билетов на культурные
              события
              {"\n"}и быстрых заказов в барах
            </span>
            <div className={b("logo-text-block")}>
              <div className={b("logo-top-text-block")}>
                <img
                  className={b("header-qr")}
                  src={headerQr}
                  alt="header-qr"
                />
                <span className={b("qr-top-mobile-text")}>
                  Установите мобильное приложение для покупки билетов на
                  культурные события и быстрых заказов в барах
                </span>
              </div>
              <QrCodeNote />
            </div>
            <div className={b("download-app")}>
              <DownloadApp />
            </div>
          </div>
          <img className={b("iphone")} src={iphone} alt="iphone" />
          <img className={b("iphone-t")} src={iphoneT} alt="iphone-t" />
        </div>
      </Content>
    </div>
  )
}
